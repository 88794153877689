var Global = function() {

    // this info should be drawn from the DB
    var slides = {
        'intro-title'                                   : 'grad-green-blue',
        'intro-overview'                                : 'grad-green-blue',
        'intro-studios'                                 : 'grad-green-blue',
        'intro-origin'                                  : 'grad-green-blue',
        'intro-directors'                               : 'grad-green-blue',
        'intro-directors-2'                             : 'grad-green-blue',
        'intro-logos'                                   : 'grad-green-blue',
        'intro-how-we-work'                             : 'grad-green-blue',
        'intro-immerse'                                 : 'grad-green-blue',
        'intro-create'                                  : 'grad-green-blue',
        'intro-design'                                  : 'grad-green-blue',
        'intro-deliver'                                 : 'grad-green-blue',

        'function-title'                                : 'grad-blue-purple',

        'function-creative-title'                       : 'grad-blue-purple',
        'function-creative-overview'                    : 'grad-blue-purple',
        'function-creative-key-people'                  : 'grad-blue-purple',
        'function-creative-service-summary'             : 'grad-blue-purple',
        'function-creative-jobs'                        : 'grad-blue-purple',

        'function-creative-services-title'              : 'grad-salmon-pink',
        'function-creative-services-overview'           : 'grad-salmon-pink',
        'function-creative-services-key-people'         : 'grad-salmon-pink',
        'function-creative-services-service-summary'    : 'grad-salmon-pink',
        'function-creative-services-jobs'               : 'grad-salmon-pink',

        'function-digital-title'                        : 'grad-yellow-green',
        'function-digital-overview'                     : 'grad-yellow-green',
        'function-digital-key-people'                   : 'grad-yellow-green',
        'function-digital-service-summary'              : 'grad-yellow-green',
        'function-digital-jobs'                         : 'grad-yellow-green',

        'function-production-title'                     : 'grad-lilac-pink-alt',
        'function-production-overview'                  : 'grad-lilac-pink-alt',
        'function-production-key-people'                : 'grad-lilac-pink-alt',
        'function-production-service-summary'           : 'grad-lilac-pink-alt',
        'function-production-jobs'                      : 'grad-lilac-pink-alt',

        'function-client-services-title'                : 'grad-orange-yellow',
        'function-client-services-overview'             : 'grad-orange-yellow',
        'function-client-services-key-people'           : 'grad-orange-yellow',
        'function-client-services-service-summary'      : 'grad-orange-yellow',
        'function-client-services-jobs'                 : 'grad-orange-yellow',

        'function-diagram'                              : 'grad-green-blue',

        'casestudies-title'                             : 'grad-green-blue',

        'copyright'                                     : 'grad-green-blue',
        'outro-title'                                   : 'grad-green-blue'
    };

    function init() {

        fullScreenAPI();
        caseStudyOrdering()
        // loadImpress();
        flexslider();
        backgrounds();
        nav();
        videos();
        loading();

    }

    function fullScreenAPI() {

        document.querySelector('#intro-title')
            .addEventListener('click', function() {

                var el = document.documentElement
                 , rfs =
                         el.requestFullScreen
                      || el.webkitRequestFullScreen
                      || el.mozRequestFullScreen;
                rfs.call(el);

            });

    }

    function caseStudyOrdering() {

       var slideWidth = 2560;
       var slideHeight = 1280;

       var slideX = $('#casestudies-title').data('x');
       var slideY = $('#casestudies-title').data('y') + slideHeight;

       var cSCollection = $('[id^="casestudy"],#copyright, #outro-title');

       var totalCS = cSCollection.length;

       var lastCS = cSCollection.first().data('casestudy');

       cSCollection.each(function(i){

           // is this the same CS as last slide?
           // check CS name against last slideslide
           var currentCS = $(this).data('casestudy');

           if (lastCS === currentCS) {
               // if Yes, move new slide right one width
               slideX += slideWidth;
           } else {
               // if No, move new slide down
               slideY += slideHeight;
           }

           lastCS = currentCS;

           // apply new x & y data var to slide
           $(this).attr( 'data-x', slideX );
           $(this).attr( 'data-y', slideY );

           if ( (i+1) === totalCS ) {
                loadImpress();
           }

       });

    }

    function loadImpress() {

        impress().init();

    }

    function flexslider() {

        $(window).load(function() {
            $('.flexslider').flexslider({
                animation     : 'fade',
                selector      : '.flexslides > li',
                prevText      : '',
                nextText      : '',
                slideshow     : false,
                controlNav    : false,
                animationLoop : false,
                keyboard      : false
            });
        });

        // use up / down arrows to navigate individual sliders
        $(document).on('impress:stepenter', function(event) {

            var id = '#' + event.target.id;
            var isFlexslider = $(id).find('.flexslider').length;

            if ( ! isFlexslider ) { return false; }

            window.onkeyup = function(e) {

                var keyCode = e.keyCode;

                if ( keyCode == 38 ) { $( id ).find('.flex-prev:not(.flex-disabled)').click(); }
                if ( keyCode == 40 ) { $( id ).find('.flex-next:not(.flex-disabled)').click(); }

            }

        });

    }

    function backgrounds() {

        $(document).on('impress:stepenter', function(event) {

            var id = event.target.id;
            var gradient = slides[id];

            $('.grad-green-blue, .grad-blue-purple, .grad-salmon-pink, .grad-yellow-green, .grad-lilac-pink-alt, .grad-orange-yellow').addClass('hidden');

            $('.' + gradient).removeClass('hidden');

            var tmpId = id.split('-');

            var casestudyStr = tmpId[0];
            var titleStr = tmpId[tmpId.length - 1]

            if ( casestudyStr === 'casestudy' && titleStr === 'title' ) {
                $('.grad-green-blue').removeClass('hidden');
            }
        });
    }

    function nav() {

        // nav has white background on video slides
        $(document).on('impress:stepenter', function(event) {

            var id = '#' + event.target.id;

            if ( $(id).find('.video').length ) {

                $('.nav').addClass('white');

            } else {

                $('.nav').removeClass('white');
            }

        });

        // add / remove underline on nav
        $(document).on('impress:stepenter', function(event) {

            var section = event.target.id.split('-')[0];

            var navClass = '';

            if ( section == 'intro' ) {

                navClass = '.introduction';

            } else if ( section == 'function' ) {

                navClass = '.functions';

            } else {

                navClass = '.case-studies';

            }

            $('.nav a').removeClass('active');

            $(navClass).addClass('active');

        });

        // nav hash logic
        $(document).on('click', '.nav a', function(event) {

            var hash = '';

            if ( $(event.target).hasClass('introduction') ) {

                hash = 'intro-title';

            } else if( $(event.target).hasClass('functions') ) {

                hash = 'function-title';

            } else if ( $(event.target).hasClass('case-studies') ) {

                hash = 'casestudies-title';

            }

            window.location.hash = hash;

        });

    }

    function videos() {

        // play video when on slide with up / down arrows
        $(document).on('impress:stepenter', function(event) {

            var id = '#' + event.target.id;

            if ( $(id).find('.video').length ) {

                window.onkeyup = function(e) {

                    if ( e.keyCode == 38 ) { $(id).find('.video').get(0).pause(); }
                    if ( e.keyCode == 40 ) { $(id).find('.video').get(0).play(); }

                }

            }

        });

    }

    function loading() {

        $(window).on('load', function() {
            $('.loading').hide();
        });

    }

    init();

}();

